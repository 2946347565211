<template>
  <div>
    <ManageForm3 />
  </div>
</template>
<script>
import ManageForm3 from "@/components/ManageForm3/ManageForm3";
export default {
  components: {
    ManageForm3,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>